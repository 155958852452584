var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "progressBar" }, [
    _c("figure", { staticClass: "progressBarContainer" }, [
      _vm.size
        ? _c(
            "svg",
            {
              staticClass: "circleChart",
              attrs: {
                viewbox: [0, 0, _vm.size, _vm.size],
                width: _vm.size,
                height: _vm.size,
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("circle", {
                class: ["circleChartBackground", _vm.theme],
                attrs: {
                  r: _vm.r,
                  cx: _vm.size / 2,
                  cy: _vm.size / 2,
                  "stroke-width": _vm.width,
                  fill: "none"
                }
              }),
              _c("circle", {
                class: ["circleChartSlide", _vm.theme],
                attrs: {
                  r: _vm.r,
                  cx: _vm.size / 2,
                  cy: _vm.size / 2,
                  "stroke-dasharray": _vm.c,
                  "stroke-dashoffset": _vm.piece,
                  "stroke-width": _vm.width,
                  fill: "none"
                }
              })
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "content" }, [
        _vm._v(" " + _vm._s(_vm.preValue) + _vm._s(_vm.currentVal) + " ")
      ])
    ]),
    _c("h4", { staticClass: "title" }, [_vm._v(" " + _vm._s(_vm.title) + " ")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseCard",
    [
      _vm.cacheStatistics
        ? _c("Progress", {
            attrs: {
              "current-val":
                _vm.cacheStatistics.used_memcached.toFixed(2) +
                " / " +
                _vm.cacheStatistics.total_memcached +
                " MB",
              percent:
                (_vm.cacheStatistics.used_memcached /
                  _vm.cacheStatistics.total_memcached) *
                100,
              size: 150,
              width: 15,
              title: _vm.$t("cacheUsed"),
              theme: "primaryBlue"
            }
          })
        : _vm._e(),
      _c(
        "BaseButton",
        {
          attrs: { type: "green", gradient: "" },
          on: { click: _vm.clearWholeCache }
        },
        [_vm._v(" " + _vm._s(_vm.$t("clearWholeCache")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }